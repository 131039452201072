<template>
    <v-form @submit.prevent="onLocalSubmit" ref="form" :rules="[hasBankOrPix]">
      <v-row dense align="center">
        <v-col cols="12">
          <v-row dense>
            <v-col :xl="4" :sm="4" :md="4" :lg="4" :cols="12">
              <v-autocomplete dense required label="Banco" v-model="currentItem.banco"
                @change="getAgency" :items="banks" item-text="nome" item-value="id" outlined clearable>

              </v-autocomplete>
            </v-col>
            <v-col :xl="3" :sm="3" :md="3" :lg="3" :cols="12">
              <v-text-field dense label="Agência" v-model="currentItem.agencia" outlined clearable/>
            </v-col>
            <v-col :xl="3" :sm="3" :md="3" :lg="3" :cols="12">
              <Input label="Conta" v-model="currentItem.conta" clearable/>
            </v-col>
            <v-col :xl="2" :sm="2" :md="2" :lg="2" :cols="12">
              <Input label="Dígito" v-model="currentItem.digito" clearable/>
            </v-col>
            <v-col cols="12">
              <Input v-model="currentItem.pix" outlined hide-details label="Chave Pix" clearable/>
            </v-col>
          </v-row>
        </v-col>
        <v-col xs="6" lg="4">
          <label>
            Preferência de Recebimento
          </label>
          <div class="d-flex mt-2">
            <v-btn-toggle v-model="preference" text mandatory color="blue accent-4">
              <v-btn>
                Pix
              </v-btn>
              <v-btn>
                Conta
              </v-btn>
            </v-btn-toggle>
          </div>
          <!-- <v-checkbox label="Prefencialmente PIX" v-model="currentItem.preferencialmente" /> -->
          <!-- <v-checkbox class="ml-4" label="Prefencialmente Depósite nesta conta" v-model="currentItem.deposite" /> -->
        </v-col>
        <v-col xs="6" lg="4">
          <label>
            Tipo da Conta
          </label>
          <div class="d-flex mt-2">
            <v-btn-toggle v-model="accountType" text mandatory color="blue accent-4">
              <v-btn>
                Corrente
              </v-btn>
              <v-btn>
                Poupança
              </v-btn>
            </v-btn-toggle>
          </div>
          <!-- <v-checkbox label="C. Corrente" v-model="currentItem.cc" class="mr-4" /> -->
          <!-- <v-checkbox label="C. Poupança" v-model="currentItem.cp" /> -->
        </v-col>
        <v-col xs="6" lg="4">
          <label>
            Tipo de Pessoa
          </label>
          <div class="d-flex mt-2">
            <v-btn-toggle v-model="personType" text mandatory color="blue accent-4">
              <v-btn>
                Física
              </v-btn>
              <v-btn>
                Jurídica
              </v-btn>
            </v-btn-toggle>
          </div>
          <!-- <v-checkbox label="P. Física" v-model="currentItem.pf" /> -->
          <!-- <v-checkbox label="P. Jurídica" v-model="currentItem.pj" /> -->
        </v-col>
        <v-col xs="12" lg="4">
          <label>
            Status
          </label>
          <div class="d-flex mt-2">
            <v-btn-toggle v-model="currentItem.ativa" mandatory class="white--text">
              <v-btn :color="!currentItem.ativa ? 'error' : ''">
                Inativo
              </v-btn>
              <v-btn :color="currentItem.ativa ? 'green' : ''">
                Ativo
              </v-btn>
            </v-btn-toggle>
          </div>
        </v-col>
        <!-- <v-col v-if="currentItem.id" :xl="1" :sm="1" :md="1" :lg="1" :cols="12" class="text-right"> -->
        <!--   <v-btn icon @click="cancela()"> -->
        <!--     <v-icon size="40">mdi-cancel</v-icon> -->
        <!--   </v-btn> -->
        <!--   <v-btn icon @click="post_update(false)" :disabled="disabled_save" :loading="loading_save"> -->
        <!--     <v-icon size="40">mdi-content-save</v-icon> -->
        <!--   </v-btn> -->
        <!-- </v-col> -->
      </v-row>
      <v-row>
        <v-col class="text-right">
          <ConfirmButton color="success">
            Salvar
          </ConfirmButton>
        </v-col>
      </v-row>
    </v-form>
</template>

<script>
import UtilsFunc from '../../../../service/utilsFunc';
import ConfirmButton from '../../../ConfirmButton.vue';
import Input from '../../../Input.vue';
import api from '@/http'

const { withCRUDUtils } = UtilsFunc

export default {
  props: {
    currentItem: Object,
    onSubmit: Function
  },
  data: () => withCRUDUtils({
    banks: [],
    accountType: 0,
    personType: 0,
  }),
  components: { ConfirmButton, Input },
  methods: {
    onLocalSubmit() {
      if (!this.hasBankOrPix()) {
        this.$toast.error("Por favor, preencha as informações do BANCO ou a CHAVE PIX.");
        return;
      }
      const isValidForm = this.$refs.form.validate()
      if (!isValidForm) return
      const updatedItem = {
        ...this.currentItem,
        cc: Boolean(!this.accountType),
        cp: Boolean(this.accountType),
        pf: Boolean(!this.personType),
        pj: Boolean(this.personType),
        pfj: Boolean(this.personType),
        preferencialmente: Boolean(!this.preference),
        deposite: Boolean(this.preference)
      }

      this.$emit('onUpdateItem', updatedItem)
      this.onSubmit(updatedItem)
    },
    // Metodo para verificar se banco ou pix estão preenchidos
    hasBankOrPix() {
    return this.currentItem.banco || this.currentItem.pix;
    },
    async getBanks() {
      const { data } = await api.get('contas/bancos/')
      this.banks = data
    },
    async getAgency() {
      //always return null
      // const { data } = await api.get(`contas/agencias/?banco=${agency}`)
      // console.log(data)
    },
  },
  mounted() {
    if (this.currentItem.ativa) {
      this.currentItem.ativa = Number(this.currentItem.ativa)
    }

    if (this.currentItem.cc !== undefined) {
      this.accountType = this.currentItem.cc ? 0 : 1
      this.personType = this.currentItem.pfj ? 1 : 0
    }
    if (this.currentItem.preferencialmente === undefined) this.currentItem.preferencialmente = false
    if (this.currentItem.deposite === undefined) this.currentItem.deposite = false
    this.getBanks()
  },
  computed: {
    preference: {
      get() {
        return this.currentItem.preferencialmente ? 0 : 1
      },
      set(value) {
        const updatedItem = {
          ...this.currentItem,
          preferencialmente: value === 0,
          deposite: value === 1
        }
        this.$emit('onUpdateItem', updatedItem)
      }
    },
  },
  watch: {
    // Adicione um watcher para a chave Pix
    'currentItem.pix': function(newVal) {
      this.preference = newVal ? 0 : 1; // Se chave Pix for preenchida, selecione Pix, senão selecione Conta
    }
  },
}
</script>
