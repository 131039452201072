<!-- Tela de Edição e/ou Novo endereço do Paciente -->
<template>
  <v-form @submit.prevent="onLocalSubmit" ref="form" >
    <v-row dense>
      <v-col class="flex-item ">
        <v-autocomplete dense v-model="currentItem.tipo" outlined :items="types" item-value="Id"
          item-text="nome" return-object :rules="requiredField" clearable>
          <template v-slot:label>
            <span>Tipo de endereço <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-text-field outlined dense @change="autofillAddressFromCep" v-mask="'#####-###'" v-model="currentItem.cep"
          :rules="requiredField" clearable>
          <template v-slot:label>
            <span>CEP <span style="color: red;">*</span></span>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-text-field outlined dense v-model="currentItem.logradouro" :rules="requiredField" clearable :loading="isLoading('get:ufs')">
          <template v-slot:label>
            <span>Logradouro <span style="color: red;">*</span></span>
          </template>
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field outlined dense v-model="currentItem.nr" :rules="requiredField" clearable>
          <template v-slot:label>
            <span>Nº <span style="color: red;">*</span></span>
          </template>
        </v-text-field>
      </v-col>
      <v-col>
        <v-autocomplete dense v-model="currentItem.uf" outlined :loading="isLoading('get:ufs')" :items="ufs"
          item-value="id" item-text="sigla" return-object @change="getLocales" :rules="requiredField" clearable>
           <template v-slot:label>
            <span>UF <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-autocomplete dense v-model="currentItem.localidade" outlined
          :loading="isLoading('get:locales')" :items="locales" item-value="id" item-text="localidade" return-object
          :rules="requiredField" clearable>
          <template v-slot:label>
            <span>Município <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-text-field outlined dense  v-model="currentItem.bairro" :rules="requiredField" clearable :loading="isLoading('get:ufs')">
          <template v-slot:label>
            <span>Bairro <span style="color: red;">*</span></span>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-text-field outlined dense label="Complemento / Referência" v-model="currentItem.complemento" clearable></v-text-field>
      </v-col>

    </v-row>
    <v-row>
      <v-col>
        <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
          <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
          <v-btn :color="currentItem.ativo ? 'green' : ''">Ativo</v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row class="justify-end dense">
        <v-col class="text-end">
          <v-tooltip top :disabled="isFormValid">
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">
                <ConfirmButton :color="isFormValid ? 'success' : 'grey'" :loading="isSubmitting" :disabled="!isFormValid || isSubmitting">Salvar</ConfirmButton>
              </span>
            </template>
            <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
          </v-tooltip>
          <!-- <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton> -->
        </v-col>
      </v-row>
  </v-form>
</template>

<script>
import ConfirmButton from '../../../ConfirmButton.vue';
import axios from 'axios'
import api from '@/http'
import UtilsFunc from '../../../../service/utilsFunc';

const { withCRUDUtils } = UtilsFunc

export default {
  name: "AddressForm",
  data: () => withCRUDUtils({
    ufs: [],
    locales: [],
    types: [],
    isSubmitting: false,
    isFormValid: false,
    requiredField: [value => !!value || 'Campo obrigatório'],
  }),
  components: { ConfirmButton },
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean,
  },
  methods: {
    onLocalSubmit() {
      if (!this.$refs.form.validate()) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios.");
        return;
      }
      this.isSubmitting = true; 
      this.onSubmit({
        ...this.currentItem
      }).catch((error) => {
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      }).finally(() => {
        this.isSubmitting = false;
      });
    },
    async getTypes() {
      const { data } = await api.get("contas/tipos-enderecos/");
      this.types = data;
      this.types = data.filter( types=> types.ativo);
    },
    async getLocales(uf) {
      const LOADING_NAME = "get:locales";
      this.setLoading(LOADING_NAME);
      const { data } = await api.get(`/uteis/localidades/?uf=${uf.sigla}`);
      this.setLoading(LOADING_NAME, true);
      this.locales = data;
    },
    async getUFS() {
      const LOADING_NAME = "get:ufs";
      this.setLoading(LOADING_NAME);
      const { data } = await api.get(`uteis/uf/`);
      this.ufs = data;
      this.setLoading(LOADING_NAME, true);
    },
    async autofillAddressFromCep(cep) {
      try {
        const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        const uf = this.ufs.find(({ sigla }) => sigla === data.uf);
        await this.getLocales(uf);
        const localidade = this.locales.find(({ localidade }) => localidade === data.localidade);
        const fullfilledItem = {
          cep,
          uf,
          logradouro: data.logradouro,
          localidade,
          municipio: localidade,
          bairro: data.bairro,
          complemento: data.complemento,
        };
        const updatedItem = { ...this.currentItem, ...fullfilledItem };
        this.$emit('onUpdateItem', updatedItem)
      }
      catch (error) {
        this.$toast.error("CEP não encontrado");
      }
    },
    validateForm() {
      this.isFormValid = !!(
        this.currentItem.tipo &&
        this.currentItem.cep &&
        this.currentItem.logradouro &&
        this.currentItem.nr &&
        this.currentItem.uf &&
        this.currentItem.localidade &&
        this.currentItem.bairro
      );
    },
  },
  computed: {
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },
  },
  mounted() {
    this.currentItem.ativo = true;
    this.getTypes();
    this.getUFS();
    if (this.currentItem.municipio) {
      this.currentItem.localidade = this.currentItem.municipio;
      this.getLocales(this.currentItem.uf);
    }
    this.currentItem.ativo = Number(this.currentItem.ativo);
    // Chama a validação inicial dos campos
    this.validateForm();
  },

  watch: {
    'currentItem.tipo': function() {
      this.validateForm();
    },
    'currentItem.cep': function() {
      this.validateForm();
    },
    'currentItem.logradouro': function() {
      this.validateForm();
    },
    'currentItem.bairro': function() {
      this.validateForm();
    },
    'currentItem.nr': function() {
      this.validateForm();
    },
    'currentItem.uf': function() {
      this.validateForm();
    },
    'currentItem.localidade': function() {
      this.validateForm();
    },
  },

}
</script>

<style scoped>
.flex-item {
  flex: 1 1 50px; 
  min-width: 100px; 
}
.flex-item-nome {
  flex: 1 1 200px; 
  min-width: 400px;
}

</style>
