<template>
  <div>
    <v-row dense>
      <v-col>
        <v-data-table :loading="isLoading('get:payments')" :headers="headers" :items="pagamentos"
          :sort-by.sync="defaultSort.name" :sort-desc.sync="defaultSort.isDesc">
          <template v-slot:top>
            <v-row>
              <v-col cols="5">
                <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify" clearable />
              </v-col>
              <v-col class="text-end">
                <v-btn class="mr-4 elevation-0" text @click="getPayments">
                  <v-icon left>
                    mdi-reload
                  </v-icon>
                  atualizar
                </v-btn>
                <v-btn color="success" class="elevation-0" @click="dialog.create = true">
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Novo
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <!-- <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-chip v-if="item.pf === true">P. FÍSICA</v-chip>
              <v-chip v-if="item.pj === true">P. JURÍDICA</v-chip>
              <v-chip v-show="item.cp === true">POUPANÇA</v-chip>
              <v-chip v-show="item.cc === true">CORRENTE</v-chip>
            </td>
          </template> -->
          <!-- <template v-slot:[`item.deposite`]="{ item }">
            <v-chip v-show="item.deposite === true" color="blue" class="white--text">DEPOSITE NESTA CONTA</v-chip>
          </template> -->
          <template v-slot:[`item.preferencialmente`]="{ item }">
            <v-chip v-if="item.preferencialmente === true" color="transparent">PIX</v-chip>
            <v-chip v-else color="transparent">Conta bancária</v-chip>
          </template>
          <template v-slot:[`item.cc`]="{ item }">
            <v-chip v-if="item.cc === true" color="transparent">Corrente</v-chip>
            <v-chip v-else color="transparent">Poupança</v-chip>
          </template>
          <template v-slot:[`item.pfj`]="{ item }">
            <v-chip v-if="item.pfj === true" color="transparent">Jurídica</v-chip>
            <v-chip v-else color="transparent">Física</v-chip>
          </template>
          <template v-slot:[`item.ativa`]="{ item }">
            <v-chip v-if="item.ativa === true" color="blue" class="white--text">ATIVA</v-chip>
            <v-chip v-else>INATIVA</v-chip>
          </template>
          <template v-slot:[`item.acoes`]="{ item }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                        <v-icon color="green">mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>Clique para editar</span>
            </v-tooltip>
        </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-if="dialog.create" v-model="dialog.create" max-width="800">
      <v-card>
        <v-card-title class="blod_color">
          Nova forma de pagamento:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.create = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <BankDetailsForm :currentItem="newItem" @onUpdateItem="e => newItem = e" :onSubmit="postPayment"
            :loading="isLoading('post:payments')" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="dialog.update" v-model="dialog.update" max-width="800">
      <v-card>
        <v-card-title class="blod_color">
          Editar forma de pagamento:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.update = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <BankDetailsForm :currentItem="selectedRow" @onUpdateItem="e => selectedRow = e" :onSubmit="putPayment"
            :loading="isLoading('put:payments')" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import api from '@/http'
import UtilsFunc from '../../../../service/utilsFunc'
import BankDetailsForm from './BankDetailsForm.vue'
import './../../../../assets/css/styles.css';

const { withCRUDUtils } = UtilsFunc

export default {
  name: 'DadosBancarios',
  components: {
    BankDetailsForm
  },
  props: {
    currentItem: Object
  },
  data: () => withCRUDUtils({
    expanded: [],
    singleExpand: false,
    loading_save: false,
    defaultSort: {
      isDesc: true,
      name: "ativa"
    },
    headers: [
      { text: "Edita dados bancários", align: "center", value: "acoes", width: 100, },
      { text: "Banco", align: "start", value: "banco.nome" },
      { text: "Agência", align: "start", value: "agencia" },
      { text: "Conta CC ou CP", align: "start", value: "cc" },
      { text: "Conta", align: "start", value: "conta" },
      { text: "Dig", align: "start", value: "digito" },
      { text: "Tipo de pessoa", align: "start", value: "pfj" },
      { text: "PIX", align: "start", value: "pix" },
      { text: "Preferência de recebimento", align: "center", value: "preferencialmente" },
      { text: "Status", align: "center", value: "ativa" },
    ],
    pagamentos: [],
    // pagamento: {
    //   preferencialmente: false,
    //   pf: false,
    //   pj: false,
    //   deposite: false,
    //   ativa: true,
    //   cp: false,
    //   // cc: false,
    //   banco: "",
    //   agencia: "",
    //   conta: "",
    //   digito: "",
    //   pix: ""
    // },
    newItem: {
      ativa: 1
    },
    bancos: [],
    agencias: [],
    bool_tipo_conta: false,
    loading: false,
  }),
  methods: {
    async getPayments() {
      const LOADING_NAME = 'get:payments'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get(`contas/formas-pagamentos/?usuario=${this.currentItem.id}`)
        this.pagamentos = data
      } catch (error) {
        this.$errorHandler(error)
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async putPayment(fields) {
      const LOADING_NAME = 'put:payments'
      this.setLoading(LOADING_NAME)
      try {
        await api.put(`contas/forma-pagamento/${fields.id}/`, { ...fields, usuario: fields.usuario.id })
        await this.getPayments()
        this.$toast.success('Forma de Pagamento Atualizada com Sucesso!')
        this.selectedRow = {}
        this.dialog.update = false
      } catch (error) {
        this.$errorHandler(error)
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true)
      }

    },
    async postPayment(fields) {
      const LOADING_NAME = 'post:payments'
      this.setLoading(LOADING_NAME)
      try {
        await api.post(`contas/formas-pagamentos/`, {
          ...fields,
          usuario: this.currentItem.id
        })
        await this.getPayments()
        this.$toast.success('Forma de Pagamento Inserida com Sucesso!')
        this.newItem = {
          ativa: 1
        }
        this.dialog.create = false
      } catch (error) {
        this.$errorHandler(error)
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
  },
  mounted() {
    this.getPayments()
  }
}
</script>
