import { render, staticRenderFns } from "./AddressForm.vue?vue&type=template&id=dd7aa416&scoped=true"
import script from "./AddressForm.vue?vue&type=script&lang=js"
export * from "./AddressForm.vue?vue&type=script&lang=js"
import style0 from "./AddressForm.vue?vue&type=style&index=0&id=dd7aa416&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd7aa416",
  null
  
)

export default component.exports