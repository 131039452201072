<template>
  <div>
    <v-form ref="formRef" @submit.prevent="_onSubmit">
      <v-row dense>
        <v-col>
          <v-text-field type="date" outlined dense @change="forceRevalidate" v-model="selectedRows.inicio_vigencia" :rules="[...requiredField]" style="min-width: 250px;">
            <template v-slot:label>
              <span>Início da vigência dos valores com o reajuste<span style="color: red;">*</span></span>
            </template>
        </v-text-field>
        </v-col>
      </v-row>
      <v-row dense> 
        <v-col cols="3">
          <v-text-field hide-details outlined dense v-model.lazy="adjusmentPercent" v-money="currencyMask" style="min-width: 250px;" >
            <template v-slot:label>
              <span>Percentual do reajuste <span style="color: red;">*</span></span>
            </template>
          </v-text-field>
        </v-col>
        <v-col class="text-end">
          <v-btn-toggle v-model="selectedRows.status" mandatory class="white--text">
            <v-btn :color="!selectedRows.status ? 'error' : ''">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">Inativo</span>
                </template>
                <span>Deixe INATIVO se HOJE não for a data de início do reajuste.</span>
              </v-tooltip>
            </v-btn>
            <v-btn :color="selectedRows.status ? 'green' : ''">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">Ativo</span>
                </template>
                <span>Deixe ATIVO se HOJE for a data de início do reajuste.</span>
              </v-tooltip>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-data-table :headers="headers" :items="selectedRows" hide-default-footer>
        <template v-slot:[`item.seg_sex`]="{ item }">
          {{ formatCurrency(item.seg_sex) }}
        </template>
        <template v-slot:[`item.sab_dom_fer`]="{ item }">
          {{ formatCurrency(item.sab_dom_fer) }}
        </template>
        <template v-slot:[`item.seg_sex_after`]="{ item }">
          {{ formatCurrency(calculateAdjusmentPrice(item.seg_sex, adjusmentPercentUnmasked)) }}
        </template>
        <template v-slot:[`item.sab_dom_fer_after`]="{ item }">
          {{ formatCurrency(calculateAdjusmentPrice(item.sab_dom_fer, adjusmentPercentUnmasked)) }}
        </template>
      </v-data-table>
      <v-row dense>
        <v-col class="text-end">
          <v-tooltip top :disabled="isFormValid">
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">
                <ConfirmButton :color="isFormValid ? 'success' : 'grey'" :loading="isSubmitting" :disabled="!isFormValid || isSubmitting">Salvar</ConfirmButton>
              </span>
            </template>
            <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import ConfirmButton from '../../../ConfirmButton.vue';

export default {
  name: "ReadjusmentTeamPrice",
  components: { ConfirmButton },
  props: {
    selectedRows: Array,
    onSubmit: Function
  },
  data: () => ({
    headers: [
      {
        value: 'seg_sex',
        text: 'Seg a Sex'
      },
      {
        value: 'sab_dom_fer',
        text: 'Sab, Dom e Feriados'
      },
      {
        value: 'seg_sex_after',
        text: 'Seg a Sex (Ajuste)'
      },
      {
        value: 'sab_dom_fer_after',
        text: 'Sab, Dom e Feriados (Ajuste)'
      },
    ],
    xmlagrupadoValues: [],
    isSubmitting: false,
    adjusmentPercent: '0',
    currencyMask: {
      decimal: ',',
      thousands: '.',
      suffix: ' %',
      precision: 2,
    },
    fim_vigencia: null,
    requiredField: [e => e !== undefined || 'Obrigatório'],
  }),
  methods: {
    _onSubmit() {
    const isValid = this.$refs.formRef.validate();
      if (!isValid) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios.");
        return;
      }
      this.isSubmitting = true;

      // Define o status baseado na data selecionada
      const today = new Date().toISOString().split('T')[0]; // Obtém a data atual no formato aaaa-mm-dd
      const statusBasedOnDate = this.selectedRows.inicio_vigencia === today ? 1 : 0;

      const newItems = this.selectedRows.map(item => ({
        ...item,
        status: statusBasedOnDate,
        fim_vigencia: this.selectedRows.fim_vigencia,
        inicio_vigencia: this.selectedRows.inicio_vigencia,
        seg_sex: this.calculateAdjusmentPrice(item.seg_sex, this.adjusmentPercentUnmasked),
        sab_dom_fer: this.calculateAdjusmentPrice(item.sab_dom_fer, this.adjusmentPercentUnmasked),
        localidade: item.localidade.id,
        colaborador: item.colaborador.id
      }));
      this.onSubmit(newItems)
      this.isSubmitting = false;
      
  },
    calculateAdjusmentPrice(price, percent) {
      return Number(price) + (price * percent) / 100
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    },
    forceRevalidate() {
      this.$nextTick(() => {
        this.$refs.formRef.validate();
      });
    },
  },
  computed: {
    adjusmentPercentUnmasked() {
    const removeSpecialCharacters = this.adjusmentPercent.replace(/\.|%/g, '');
    const changeDecimalSign = removeSpecialCharacters.replace(',', '.');
    return parseFloat(changeDecimalSign) || 0;
  },
  // dateRules() {
  //   return [
  //     e => !!e || 'Data obrigatória',
  //     e => {
  //       const fimVigencia = this.selectedRows.fim_vigencia;
  //       return !fimVigencia || new Date(e) >= new Date(fimVigencia) || 'A data de início deve ser posterior à data de fim da vigência';
  //     }
  //   ];
  // },
  isFormValid() {
    return !! (this.adjusmentPercentUnmasked > 0 && // Verifica se o percentual é maior que 0
      this.selectedRows.inicio_vigencia); 
  },
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },
  },
  watch: {
  // 'selectedRows.fim_vigencia'() {
  //   this.$refs.formRef.validate(); // Revalida o formulário
  // },
  'selectedRows.inicio_vigencia'() {
    this.$refs.formRef.validate(); // Revalida o formulário
  },
},

}
</script>
