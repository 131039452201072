<template>
  <v-form @submit.prevent="onLocalSubmit" ref="form">
    <v-row dense>
      <v-col>
        <vuetify-money v-model="currentItem.seg_sex" label="Segunda à sexta" outlined clearable valueWhenIsEmpty=""
          :options="options" dense :rules="requiredField" />
      </v-col>
      <v-col>
        <vuetify-money v-model="currentItem.sab_dom_fer" label="Sabado, Domingo e feriados" outlined clearable
          valueWhenIsEmpty="" :options="options" dense :rules="requiredField" />
      </v-col>
    </v-row>
    <v-row dense>
      <v-text-field v-model="currentItem.inicio_vigencia" type="date" outlined clearable dense :rules="requiredField">
        <template v-slot:label>
            <span>Início da vigência <span style="color: red;">*</span></span>
        </template>
      </v-text-field>
    </v-row>
    <v-row dense>
      <v-text-field v-model="currentItem.fim_vigencia" type="date" label="Fim da vigência" outlined clearable dense >
      </v-text-field>
    </v-row>
    <v-row dense> 
      <v-col dense>
        <v-btn-toggle v-model="currentItem.status" mandatory class="white--text">
          <v-btn :color="!currentItem.status ? 'error' : ''">
            Inativo
          </v-btn>
          <v-btn :color="currentItem.status ? 'green' : ''">
            Ativo
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col class="text-end">
          <v-tooltip top :disabled="isFormValid">
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">
                <ConfirmButton :color="isFormValid ? 'success' : 'grey'" :loading="isSubmitting" :disabled="!isFormValid || isSubmitting">Salvar</ConfirmButton>
              </span>
            </template>
            <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
          </v-tooltip>
        </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ConfirmButton from '../../../ConfirmButton.vue';
import UtilsFunc from '../../../../service/utilsFunc';

const { withCRUDUtils } = UtilsFunc

export default {
  name: "DadosPessoaisForm",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean
  },
  components: { ConfirmButton },
  data: () => withCRUDUtils({
    requiredField: [e => e !== undefined || 'Obrigatório'],
    isSubmitting: false,
    isFormValid: false,
    options: {
      locale: "pt-BR",
      prefix: "R$",
      suffix: "",
      length: 11,
      precision: 2
    },
  }),
  methods: {
    onLocalSubmit() {
      const isValid = this.$refs.form.validate()
      if (!isValid) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios!");
        return;
      }
      this.isSubmitting = true; 
      this.onSubmit({
        ...this.currentItem
      }).catch((error) => {
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      }).finally(() => {
        this.isSubmitting = false;
      }); 
    },
    validateForm() {
      this.isFormValid = !! this.currentItem.inicio_vigencia
    },
    checkAndSetStatus() {
      if (this.currentItem.fim_vigencia) {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Zera o horário para a comparação se concentrar apenas na data
        const fimVigencia = new Date(this.currentItem.fim_vigencia);
        fimVigencia.setHours(0, 0, 0, 0); // Zera o horário

        if (fimVigencia < today) {
          this.currentItem.status = 0; // Define como inativo
        } else {
          this.currentItem.status = 1; // Define como ativo
        }
      } else {
        this.currentItem.status = 1; // Define como ativo se a data fim_vigencia estiver vazia
      }
    },

  },
  computed: {
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },
  },
  mounted() {
    this.currentItem.status = Number(this.currentItem.status)
    this.validateForm();
    this.currentItem.seg_sex = this.currentItem.seg_sex || '0.00';
    this.currentItem.sab_dom_fer = this.currentItem.sab_dom_fer || '0.00';
  },
  watch: {
    'currentItem.inicio_vigencia': function() {
      this.validateForm();
    },
    'currentItem.fim_vigencia': function() {
      this.checkAndSetStatus();
      this.validateForm();
    },
    'currentItem.seg_sex': function(newVal) {
    if (!newVal) {
      this.$set(this.currentItem, 'seg_sex', '0.00');
    }
    },
    'currentItem.sab_dom_fer': function(newVal) {
      if (!newVal) {
        this.$set(this.currentItem, 'sab_dom_fer', '0.00');
      }
    },
  },

}
</script>
