<template>
  <div>
    <v-row dense>
      <v-col :xl="12" :sm="12" :md="12" :lg="12" :cols="12">
        <v-data-table :headers="headers" :items="tableData" :loading="isLoading('get:address')"
          :sort-by.sync="defaultSort.name" :sort-desc.sync="defaultSort.isDesc">
          <template v-slot:top>
            <v-row>
              <v-col cols="5">
                <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify" clearable/>
              </v-col>
              <v-col class="text-end">
                <v-btn class="mr-4 elevation-0" text @click="getAddress">
                  <v-icon left>
                    mdi-reload
                  </v-icon>
                  atualizar
                </v-btn>
                <v-btn color="success" class="elevation-0" @click="dialog.create = true">
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Novo
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.ativo`]="item">
            <v-chip class="ma-2" :color="item.item.ativo ? 'blue' : 'grey'">
              <span class="white--text">{{ item.item.ativo ? 'Ativo' : 'Inativo' }}</span>
            </v-chip>
          </template>
          <template v-slot:[`item.acoes`]="{ item }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                        <v-icon color="green">mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>Clique para editar</span>
            </v-tooltip>
        </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-if="dialog.create" v-model="dialog.create" max-width="800">
      <v-card>
        <v-card-title class="blod_color">
          Novo Endereço:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.create = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <AddressForm :currentItem="newItem" @onUpdateItem="e => newItem = e" :onSubmit="postAddress"
            :loading="isLoading('post:address')" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="dialog.update" v-model="dialog.update" max-width="800">
      <v-card>
        <v-card-title class="blod_color">
          Editar Endereço:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.update = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <AddressForm :currentItem="selectedRow" @onUpdateItem="e => selectedRow = e" :onSubmit="putAdress"
            :loading="isLoading('put:address')" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import api from '@/http'
import UtilsFunc from '../../../../service/utilsFunc'
import AddressForm from '../pacientes/AddressForm.vue'
import './../../../../assets/css/styles.css';

const { withCRUDUtils } = UtilsFunc

export default {
  name: "Enderecos",
  props: {
    currentItem: Object
  },
  components: {
    AddressForm
  },
  data: () => withCRUDUtils({
    newItem: {
      ativo: 1
    },
    headers: [
      { text: "Editar Endereço", value: "acoes", width: 50, sortable: false},
      { text: "Tipo", value: "tipo.nome" },
      { text: "CEP", value: "cep" },
      { text: "Logradouro", value: "logradouro" },
      { text: "Nº", value: "nr" },
      { text: "Bairro", value: "bairro" },
      { text: "Complemento", value: "complemento" },
      { text: "Município", value: "localidade.localidade" },
      { text: "UF", value: "uf.sigla" },
      { text: "Status", value: "ativo" },
    ],
    defaultSort: {
      isDesc: true,
      name: "ativo"
    },
  }),
  methods: {
    async getAddress() {
      const LOADING_NAME = "get:address"
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get(`contas/enderecos/?enderecos=${this.currentItem.id}`)
        this.tableData = data
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async postAddress() {
      const LOADING_NAME = "post:address"
      this.setLoading(LOADING_NAME)
      try {
        const fields = {
          ...this.newItem,
          ativo: Boolean(this.newItem),
          usuario: {
            id: this.currentItem.id
          }
        }
        await api.post('contas/enderecos/', fields)
        await this.getAddress()
        this.$toast.success('Endereço inserido com sucesso!')
        this.dialog.create = false
        this.newItem = {
          ativo: 1
        }
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async putAdress() {
      const LOADING_NAME = "put:address"
      this.setLoading(LOADING_NAME)
      try {
        await api.put(`contas/endereco/${this.selectedRow.id}/`, this.selectedRow)
        await this.getAddress()
        this.$toast.success('Endereço atualizado com sucesso!')
        this.dialog.update = false
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
  },
  mounted() {
    this.getAddress();
  }
}
</script>
