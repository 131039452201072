<template>
  <v-text-field outlined dense v-bind="$attrs" v-on="$listeners" />
</template>

<script>
export default {
  name: "Input",
  props: {},
  data: () => ({}),
  methods: {
  },
}
</script>
