<template>
  <v-row v-if="isLoading" class="d-flex justify-center py-8">
    <v-progress-circular indeterminate />
  </v-row>
  <v-row v-else dense>
  <v-row dense>
    <v-col>
        <template v-if="patients && patients.length > 0">
            <strong>Pacientes: </strong>
            <p></p>
            <ul style="list-style: none; padding-left: 0">
                <li :key="patient.id" v-for="patient in patients">
                    <p>ID: {{ patient.id }} -> {{ patient.nome }}</p>
                </li>
            </ul>
        </template>
        
        <p v-else class="text"> <b>Não há Pacientes Cadastrados.</b></p>
    </v-col>
    <v-col>
      <strong> Dados Pessoais: </strong>
      <p></p>
      <p> <b> Zonas de Atendimento: </b> {{ zonaNomes ? zonaNomes : "Sem zonas cadastradas" }} </p>
      <p> <b> Contrato PF ou PJ: </b> {{ currentItem.cnpj ? "Contrato Pessoa Jurídica" : "Conrato Pessoa Física" }}  </p>
      <p> <b> Data de Nascimento: </b> {{ currentItem.nascimento ? formatDateSimples(currentItem.nascimento) : ''}} </p>
      <p> <b> CPF: </b> {{ currentItem.cpf ? formatCPF(currentItem.cpf) : ''}} </p>
      <p> <b> RG: </b> {{ currentItem.rg ? currentItem.rg : ''}}</p>
      <p v-if="currentItem.data_contrato"> <b>Data início contrato: </b> {{ formatDateSimples(currentItem.data_contrato) }} </p><p v-else> <b>Data início contrato:</b> </p>
      <p> <b> Conselho:</b> {{ currentItem.conselho ? currentItem.conselho.nome : ''}} </p>
      <p> <b> UF do Conselho: </b> {{ currentItem.uf_inscricao_conselho && currentItem.uf_inscricao_conselho.sigla ? currentItem.uf_inscricao_conselho.sigla : ''}} </p>
      <p> <b> Nº do Conselho: </b> {{ currentItem.nconselho ? currentItem.nconselho : ''}} </p>
    </v-col>

    <v-col v-if="currentItem.dados_bancarios.length > 0">
      <strong> Dados Bancários: </strong>
      <p></p>
      <div
        v-for="(dados, k) in currentItem.dados_bancarios.filter(
          (banco) => banco.ativa
        )"
        :key="k"
      >
        <p> <b> Tipo de Conta: </b> {{ dados.pfj ? "Pessoa Jurídica" : "Pessoa Física" }} </p>
        <p> <b> Prefêrencia de Recebimento: </b> {{ dados.preferencialmente ? "PIX" : "Conta Bancária" }} </p>
        <p> <b> Banco: </b> {{ dados.banco ? dados.banco.nome : '' }} </p>
        <p> <b> Agência: </b> {{ dados.agencia ? dados.agencia : '' }} </p>
        <p> <b> Conta CC ou CP: </b> {{ dados.cc ? "Corrente" : "Poupança" }} </p>
        <p> <b> Conta e Digito: </b> {{ dados.conta ? dados.conta : '' }} - {{ dados.digito ? dados.digito : '' }} </p>
        <p> <b> PIX: </b> {{ dados.pix ? dados.pix : '' }} </p>
      </div>
    </v-col>
    <v-col v-else class="text"><b> Favor cadastrar Dados Bancários</b></v-col>
    <!-- <v-col>
      <strong> Modificações: </strong>
      <p></p>
      <p>
        Criado em: <b>{{ formatDate(currentItem.created_at) }}</b>
      </p>
      <p>
        Criado por: <b>{{ formatDate(currentItem.created_by) }}</b>
      </p>
      <p>
        Editado em: <b>{{ formatDate(currentItem.modified_at) }}</b>
      </p>
      <p>
        Modificado por: <b>{{ currentItem.modified_by }}</b>
      </p>
    </v-col> -->
  </v-row>
  </v-row>
</template>

<script>
import Moment from "moment";
import api from "../../../../http";

export default {
  name: "ExpandedMultiTeamItem",
  props: {
    currentItem: {},
  },
  data: () => ({
    patients: [],
    loading: false,
    isLoading: true,
    zonas: [],
  }),
  methods: {
    async getPatients() {
      this.isLoading = true
      try {
        const { data } = await api.get("/pacientes/pacientes/");
        const filterPatients = data.filter((patient) =>
          patient.equipe_multi_disciplinar.includes(this.currentItem.id)
        );
        this.patients = filterPatients;
        this.isLoading = false
      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      }
    },
    formatDate(date) {
      if (!date) return;
      return new Moment(date).locale("pt-br").format("DD/MM/YYYY HH:MM");
    },
    formatDateSimples(dateString) {
      if (!dateString) return '';
      const parts = dateString.split('-');
      return `${parts[2]}/${parts[1]}/${parts[0]}`;
    },
    formatCPF(cpf) {
      if (!cpf) return '';
      const parts = cpf.match(/(\d{3})(\d{3})(\d{3})(\d{2})/);
      if (parts) {
        return `${parts[1]}.${parts[2]}.${parts[3]}-${parts[4]}`;
      }
      return cpf;
    },
    async getZonas(){
      const { data } = await api('contas/zonas/')
      this.zonas = data.filter(zona => zona.ativo);
    },
  },
  computed: {
    zonaNomes() {
      // Verifica se currentItem.zona existe e tem pelo menos um ID, e se zonas está carregado
      if (!this.currentItem.zona || this.currentItem.zona.length === 0 || this.zonas.length === 0) {
        return ""; // Retorna string vazia se não houver zonas para mostrar
      }
      // Mapeia os IDs de zona para nomes, filtrando para ignorar IDs sem correspondência
      const nomes = this.currentItem.zona.reduce((acc, zonaId) => {
        const zonaObj = this.zonas.find(z => z.id === zonaId);
        if (zonaObj) acc.push(zonaObj.nome); // Adiciona o nome se encontrou correspondente
        return acc;
      }, []);
      
      return nomes.join(', ') || ""; // Junta os nomes com vírgula ou retorna string vazia se não houver nomes
    }
  },
  mounted() {
    this.getZonas();
    this.getPatients();
  },
};
</script>

<style scoped>
.text {
  color: red;
}
</style>
